/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components), {ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signia-silk--small-scale-premium-cic-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk--small-scale-premium-cic-hearing-aids",
    "aria-label": "signia silk  small scale premium cic hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Silk- Small-scale Premium CIC Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Designed by Signia, a subsidiary of the Singapore-based Sivantos Group, the Signia Silk is a compact, technologically advanced binaural type of completely-in-canal (CIC) hearing aid. The most important feature of these hearing aids is their size, which makes them virtually invisible when the user is wearing them. But they aren’t just a slimmed-down, under-powered version of Signia’s other CIC or BTE devices. Despite their small size, Signia Silk hearing aids deliver a high level of performance and include some advanced features, so let’s learn more about what they have to offer, and who would be a suitable user."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-ideal-option-for-first-time-wearers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-ideal-option-for-first-time-wearers",
    "aria-label": "the ideal option for first time wearers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Ideal Option for First-Time Wearers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When people first start wearing hearing aids, comfort and size are major considerations. If you aren’t happy to have a highly visible hearing aid, the Signia Silk will be the perfect fit. As we’ve noted already, they are extremely small, and practically impossible to see from the outside. And they also include “Click Sleeves”, which make them simple and comfortable to wear. These sleeves are made out of soft silicone, which molds itself to the shape of the wearer’s ear canal. In the past, a custom fit would have been required to achieve this level of comfort and stability, but Signia has engineered a custom-fit into their design. This is something that all wearers will appreciate but is particularly handy for inexperienced hearing aid users who need a hassle-free, quick solution. With small, medium, and large click sleeves available, users still need to pick the correct option for their ear shape, but that should be fairly simple. After that, wearing Signia Silk buds will be as comfortable as any custom-fit alternatives."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "advanced-sonic-technologies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#advanced-sonic-technologies",
    "aria-label": "advanced sonic technologies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Advanced Sonic Technologies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia Silk hearing aids combine this ease of use and compact size with features that would normally be reserved for larger versions. The core of the package is what is known ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/x",
    className: "c-md-a"
  }, "as the Nx chip"), " – a processor designed for Signia, which powers various subsidiary features. Nx hooks up with a powerful microphone, which is optimized to focus on sounds directly in front of the user. That way, they should blank out excessive background noise, making it easier to carry out conversations and hear instructions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When connected to a Signia easyTek audio app and device, users will be able to fine-tune their hearing aids. Finally, the Nx chip also allows the Signia Silk to assist people with tinnitus. Users can choose a special tinnitus therapy mode, which creates a soft tone or ocean wave noises to ease the symptoms of ringing in the ears. It even includes a more advanced “notch therapy” setting to help the brain relegate tinnitus noises into the backdrop."), "\n", React.createElement(ButtonCta, {
    copy: "Start your risk-free trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "different-versions-of-the-signia-silk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#different-versions-of-the-signia-silk",
    "aria-label": "different versions of the signia silk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Different Versions of the Signia Silk"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia Silk doesn’t come in a single version. We noted above that the click sleeves come in a range of sizes, so that they can accommodate different ear canal dimensions. These sleeves are supplied independently of the hearing aids, so there’s no need to stick to one size. If you find that an initial fitting has become uncomfortable, you can move up or down the size range with ease."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, the hearing aids also come in three performance classes: 3nx, 5nx, and 7nx. Those with more severe hearing loss will require the higher performance versions (7nx), but most people will do fine with the 3nx. It’s best to consult a specialist to ensure that you get the ideal hearing aid, but be aware that there is a significant price difference between the 3nx and the 7nx. So, try not to buy a level of power that you don’t need."), "\n", React.createElement(ButtonCta, {
    copy: "Start your risk-free trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-nx-logo.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-nx-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-nx-technology",
    "aria-label": "signia nx technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/x",
    className: "c-md-a"
  }, "Signia Nx Technology")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Xperience is a revolutionary technology platform from Signia, the company that has taken over Siemens’ well-known range of audiological devices. Available in Behind-the-Ear and Receiver-in-Canal models, Xperience is one of the most advanced hearing aid technologies around.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-silk.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-silk-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk-x",
    "aria-label": "signia silk x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/silk",
    className: "c-md-a"
  }, "Signia Silk X")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for a hearing aid that is as small as a coffee bean and unnoticeable to those around you, the Signia Silk X is the perfect fit. It’s one of the smallest hearing aids on the market today, disappearing inside your ear. These ITE hearing aids also include “Click Sleeves,” which make them simple and comfortable to wear, as they’re made out of soft silicone that molds itself to the shape of your ear canal.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-dark-granite.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-styletto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto",
    "aria-label": "signia styletto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/styletto",
    className: "c-md-a"
  }, "Signia Styletto")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "This slim, behind-the-ear Bluetooth hearing aid has transformed the future of modern hearing aid design. Designed for the active lifestyle, the rechargeable Styletto X hearing aid combines elegance, convenience and functionality to deliver a high-quality hearing experience without compromising style. And no one will know you’re wearing it.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/binaural-hearing-aids.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure",
    "aria-label": "signia pure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/pure",
    className: "c-md-a"
  }, "Signia Pure")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia range of Pure hearing aids is incredible no matter your level of hearing loss. With these powerful Bluetooth hearing aids that sit discreetly behind your ear, you’ll be able to stream phone calls, TV and audio right into your ears.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
